import { BehaviorSubject } from 'rxjs';

export class FaceShareHelper {
  private static loadingSubject      = new BehaviorSubject<boolean>(false);
  private static loadingCount        = 0;
  private static lstAllOptionsData   = new BehaviorSubject<any>(null);
  private static salesDateSubject    = new BehaviorSubject<any>(null);
  private static dateInJobClearCache = new BehaviorSubject<string>('');

  // Loading
  static setLoading(loading: boolean) {
    return this.loadingSubject.next(loading);
  }

  static isLoading() {
    return this.loadingSubject.asObservable();
  }

  static startLoading() {
    this.loadingCount++;
    this.loadingSubject.next(true);
  }

  static stopLoading() {
    this.loadingCount--;

    if (this.loadingCount === 0) {
      this.loadingSubject.next(false);
    }
  }

  // Filters data
  static getLstAllOptionsData() {
    return this.lstAllOptionsData.asObservable();
  }

  // Salesdate data
  static setSalesDate(value: string[]) {
    if (this.salesDateSubject.value) {
      return;
    }

    return this.salesDateSubject.next(value);
  }

  static getSalesDate() {
    return this.salesDateSubject.asObservable();
  }

  // DateInJob data
  static setDateInJobClearCache(value: string) {
    return this.dateInJobClearCache.next(value);
  }

  static getDateInJobClearCache() {
      return this.dateInJobClearCache.asObservable();
  }

  // Compare dates
  static compareDates(date1: string, date2: string) {
    const fullDate1 = `${date1}-01`;

    console.log({fullDate1, date2});

    const d1 = new Date(fullDate1);
    const d2 = new Date(date2);

    return d1 <= d2;
  }

  // MarkerCategory chart
  static getMarkerCategories() {
    return [
      {
        label: 'All Category',
        value: 'ALL_CATEGORY'
      },
      {
        label: 'SSD Total',
        value: 'SSD_TOTAL'
      },
      {
        label: 'Cola SSD',
        value: 'COLA_SSD'
      },
      {
        label: 'Other SSD',
        value: 'OTHER_SSD'
      },
      {
        label: 'Coffee',
        value: 'COFFEE'
      },
      {
        label: 'Juice',
        value: 'JUICE'
      },
      {
        label: 'NS Tea',
        value: 'NS_TEA'
      },
      {
        label: 'Black Tea',
        value: 'BLACK_TEA'
      },
      {
        label: 'Sports',
        value: 'SPORTS'
      },
      {
        label: 'Energy',
        value: 'ENERGY'
      },
      {
        label: 'Water',
        value: 'WATER'
      },
      {
        label: 'Others',
        value: 'OTHERS'
      }
    ];
  }

  static getAreaCategories() {
    return [
      {
        label: 'KO',
        value: 'KO_SHARE',
        image: 'Coca-Cola.png'
      },
      {
        label: 'Suntory',
        value: 'SUNTORY_SHARE',
        image: 'Suntory.png'
      },
      {
        label: 'Kirin',
        value: 'KIRIN_SHARE',
        image: 'Kirin.png'
      },
      {
        label: 'Asahi',
        value: 'ASAHI_SHARE',
        image: 'Asahi.png'
      },
      {
        label: 'Itoen',
        value: 'ITOEN_SHARE',
        image: 'Itoen.png'
      },
      {
        label: 'Others',
        value: 'OTHERS_SHARE'
      },
      {
        label: 'KO',
        value: 'KO_DIFF_VS_LY',
        image: 'Coca-Cola.png'
      },
      {
        label: 'Suntory',
        value: 'SUNTORY_DIFF_VS_LY',
        image: 'Suntory.png'
      },
      {
        label: 'Kirin',
        value: 'KIRIN_DIFF_VS_LY',
        image: 'Kirin.png'
      },
      {
        label: 'Asahi',
        value: 'ASAHI_DIFF_VS_LY',
        image: 'Asahi.png'
      },
      {
        label: 'Itoen',
        value: 'ITOEN_DIFF_VS_LY',
        image: 'Itoen.png'
      },
      {
        label: 'Others',
        value: 'OTHERS_DIFF_VS_LY'
      },
    ];
  }

  static getAccountCategories() {
    return [
      // All Category
      {
        label: 'KO',
        value: 'ALL_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'ALL_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'ALL_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'ALL_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'ALL_ITOEN',
        image: 'Itoen.png',
      },
      // SSD
      {
        label: 'KO',
        value: 'SSD_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'SSD_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'SSD_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'SSD_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'SSD_ITOEN',
        image: 'Itoen.png',
      },
      // Cola SSD
      {
        label: 'KO',
        value: 'COCA_SSD_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'COCA_SSD_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'COCA_SSD_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'COCA_SSD_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'COCA_SSD_ITOEN',
        image: 'Itoen.png',
      },
      // Other SSD
      {
        label: 'KO',
        value: 'OTHER_SSD_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'OTHER_SSD_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'OTHER_SSD_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'OTHER_SSD_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'OTHER_SSD_ITOEN',
        image: 'Itoen.png',
      },
      // Coffee
      {
        label: 'KO',
        value: 'COFFEE_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'COFFEE_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'COFFEE_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'COFFEE_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'COFFEE_ITOEN',
        image: 'Itoen.png',
      },
      // Juice
      {
        label: 'KO',
        value: 'JUICE_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'JUICE_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'JUICE_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'JUICE_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'JUICE_ITOEN',
        image: 'Itoen.png',
      },
      // NS Tea
      {
        label: 'KO',
        value: 'NSTEA_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'NSTEA_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'NSTEA_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'NSTEA_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'NSTEA_ITOEN',
        image: 'Itoen.png',
      },
      // Black Tea
      {
        label: 'KO',
        value: 'BLACK_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'BLACK_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'BLACK_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'BLACK_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'BLACK_ITOEN',
        image: 'Itoen.png',
      },
      // Sports
      {
        label: 'KO',
        value: 'SPORTS_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'SPORTS_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'SPORTS_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'SPORTS_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'SPORTS_ITOEN',
        image: 'Itoen.png',
      },
      // Energy
      {
        label: 'KO',
        value: 'ENERGY_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'ENERGY_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'ENERGY_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'ENERGY_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'ENERGY_ITOEN',
        image: 'Itoen.png',
      },
      // Water
      {
        label: 'KO',
        value: 'WATER_KO',
        image: 'Coca-Cola.png',
      },
      {
        label: 'Suntory',
        value: 'WATER_SUNTORY',
        image: 'Suntory.png',
      },
      {
        label: 'Kirin',
        value: 'WATER_KIRIN',
        image: 'Kirin.png',
      },
      {
        label: 'Asahi',
        value: 'WATER_ASAHI',
        image: 'Asahi.png',
      },
      {
        label: 'Itoen',
        value: 'WATER_ITOEN',
        image: 'Itoen.png',
      },
    ];
  }
}
