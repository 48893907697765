import { Injectable } from '@angular/core';
import { getCurrentDateAsNumber } from 'src/app/helpers/date';
import { BehaviorSubject, Observable } from 'rxjs';
import { EXCLUDE_TRANSACTION_RECORDS } from 'src/app/model/constants';

interface CleanupState {
  lastCleanupDate: number;
  isCleanedToday: boolean;
  failedAttempts: number;
  clearingInProgress: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class IndexedDBCleanupService {
  private readonly DB_NAME         = 'Analytics';
  private readonly STORE_NAME      = 'Report';
  private readonly CURRENT_DATE    = getCurrentDateAsNumber();

  private readonly CLEANUP_STATE_KEY = 'indexeddb_cleanup_state';
  private cleanupStateSubject        = new BehaviorSubject<CleanupState>(this.getInitialState());
  public cleanupState$: Observable<CleanupState> = this.cleanupStateSubject.asObservable();

  constructor() {
    this.loadState();
  }

  /**
   * Get initial state or from localStorage if available
   */
  private getInitialState(): CleanupState {
    return {
      lastCleanupDate: 0,
      isCleanedToday: false,
      failedAttempts: 0,
      clearingInProgress: false
    };
  }

  /**
   * Load state from localStorage
   */
  private loadState(): void {
    try {
      const savedState = localStorage.getItem(this.CLEANUP_STATE_KEY);
      if (savedState) {
        const parsedState = JSON.parse(savedState) as CleanupState;

        const today     = new Date();
        const savedDate = new Date(Math.floor(parsedState.lastCleanupDate / 10000),
                                 (parsedState.lastCleanupDate % 10000) / 100 - 1,
                                 parsedState.lastCleanupDate % 100);

        if (today.getDate() !== savedDate.getDate() || today.getMonth() !== savedDate.getMonth() || today.getFullYear() !== savedDate.getFullYear()) {
          parsedState.isCleanedToday = false;
        }

        this.cleanupStateSubject.next(parsedState);
      }
    } catch (error) {
      console.error('Error loading cleanup state:', error);
      this.cleanupStateSubject.next(this.getInitialState());
    }
  }

  /**
   * Save state to localStorage
   */
  private saveState(): void {
    try {
      localStorage.setItem(this.CLEANUP_STATE_KEY, JSON.stringify(this.cleanupStateSubject.value));
    } catch (error) {
      console.error('Error saving cleanup state:', error);
    }
  }

  /**
   * Update state
   */
  private updateState(stateChanges: Partial<CleanupState>): void {
    this.cleanupStateSubject.next({
      ...this.cleanupStateSubject.value,
      ...stateChanges
    });
    this.saveState();
  }

  /**
   * Check if database cleanup is needed
   * @returns boolean True if cleanup is needed, False if not
   */
  public needsCleanup(): boolean {
    const state = this.cleanupStateSubject.value;

    if (state.isCleanedToday) {
      return false;
    }

    if (state.clearingInProgress) {
      return false;
    }

    return true;
  }

  /**
   * Clear expired data from IndexedDB
   * @returns Promise<string> Result message
   */
  public async clearExpiredData(): Promise<string> {
    if (!this.needsCleanup()) {
      return Promise.resolve('Cleanup already performed today, skipping');
    }

    this.updateState({ clearingInProgress: true });

    return new Promise<string>((resolve, reject) => {
      try {
        const request = indexedDB.open(this.DB_NAME);

        request.onerror = () => {
          this.handleCleanupError(`Can not open the database ${this.DB_NAME}: ${request.error}`);
          reject(`Can not open the database ${this.DB_NAME}: ${request.error}`);
        };

        request.onsuccess = () => {
          let totalDeleted = 0;
          let totalProcessed = 0;
          let totalExcluded = 0;

          const db = request.result;
          const objectStoreNames = [this.STORE_NAME];

          if (!db.objectStoreNames.contains(this.STORE_NAME)) {
            db.close();
            this.handleCleanupSuccess(0);
            resolve('Object store not found, skipping cleanup');
            return;
          }

          const transaction = db.transaction(objectStoreNames, 'readwrite');

          transaction.onerror = () => {
            this.handleCleanupError(`Transaction error: ${transaction.error}`);
            reject(`Transaction error: ${transaction.error}`);
          };

          objectStoreNames.forEach(storeName => {
            const objectStore = transaction.objectStore(storeName);
            const cursorRequest = objectStore.openCursor();

            cursorRequest.onsuccess = (event) => {
              const cursor = (event.target as IDBRequest).result;

              if (cursor) {
                const record = cursor.value;
                totalProcessed++;

                const recordId = record.id || record._id || cursor.primaryKey;
                const shouldExclude = EXCLUDE_TRANSACTION_RECORDS.includes(recordId);

                if (shouldExclude) {
                  totalExcluded++;
                  cursor.continue();
                  return;
                }

                if (!record.hasOwnProperty('expiration') ||
                    (record.expiration && parseInt(record.expiration) < this.CURRENT_DATE)) {
                  const deleteRequest = cursor.delete();
                  deleteRequest.onsuccess = () => totalDeleted++;
                }

                cursor.continue();
              }
            };
          });

          transaction.oncomplete = () => {
            db.close();
            this.handleCleanupSuccess(totalDeleted);
            resolve(`Processed ${totalProcessed} records: deleted ${totalDeleted}, excluded ${totalExcluded} from database ${this.DB_NAME}`);
          };
        };

        request.onupgradeneeded = (event) => {
          const db = (event.target as IDBRequest).result;
          db.close();
          this.handleCleanupSuccess(0);
          resolve('Database does not exist or is being upgraded');
        };
      } catch (error) {
        this.handleCleanupError(`Unknown error: ${error}`);
        reject(`Unknown error: ${error}`);
      }
    });
  }

  /**
   * Handle successful cleanup
   */
  private handleCleanupSuccess(deletedCount: number): void {
    this.updateState({
      lastCleanupDate: this.CURRENT_DATE,
      isCleanedToday: true,
      failedAttempts: 0,
      clearingInProgress: false
    });
    console.log(`IndexedDB cleanup successful, deleted ${deletedCount} records`);
  }

  /**
   * Handle cleanup failure
   */
  private handleCleanupError(error: string): void {
    const currentState = this.cleanupStateSubject.value;
    this.updateState({
      failedAttempts: currentState.failedAttempts + 1,
      clearingInProgress: false
    });
    console.error(`IndexedDB cleanup failed: ${error}`);
  }

  /**
   * Reset cleanup state - forces cleanup on next check
   * Useful when wanting to force cleanup after an error
   */
  public resetCleanupState(): void {
    this.updateState({
      isCleanedToday: false,
      failedAttempts: 0,
      clearingInProgress: false
    });
  }

  /**
   * Set list of records to exclude from cleanup
   */
  public setExcludedRecords(recordIds: string[]): void {
    EXCLUDE_TRANSACTION_RECORDS.length = 0;
    EXCLUDE_TRANSACTION_RECORDS.push(...recordIds);
  }
}
