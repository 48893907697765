<div id="pdf_print_area_2" #pdf_print_area_2 class="row" class="row">
    <div class="col-lg-12 col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>VM Market Value Share by Area</h3>
            </div>
            <div class="filter-section no-title ssmm-filter otc-region-section">

                <!-- <div class="ng-section summary-section native">
                        <b>Aggregate By</b>
                        <select class="form-control filter-by-category form-basic option-sankey" (change)="onAggregateChange($event.target.value)">
                            <option value="wtd">Weekly</option>
                            <option value="mtd">Monthly</option>
                        </select>
                </div> -->
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Week to Date'}, {value: 'mtd', name: 'Month to Date'}]"
                    [model]="aggregateBy"
                    (change)="onAggregateChange($event)">
                </single-dropdown>                

            </div>
            <div class="card-body">
                <div #scroll class="market-size region-market-size">
                    <table #screen class="intage-market-size">
                        <tr>
                            <th [attr.colspan]="4" class="summary-row-col"></th>
                            <th *ngFor="let item of categories; index as i;" [style]="'background-color:' + getCategoryColor(item)">{{getCategoryDisplayName(item)}}</th>
                        </tr>
                        <ng-container>
                            <tr>
                                <th colspan="2" class="summary-row-col" [attr.rowspan]="20">
                                    <ng-container *ngIf="selectedMakerLogo != ''">
                                        <img class="maker-logo" [src]="selectedMakerLogo"/>
                                    </ng-container>
                                    <ng-container *ngIf="selectedMakerLogo == ''">
                                        <span style="word-break: break-all;">{{selectedMaker}}</span>
                                    </ng-container>
                                </th>
                            </tr>
                        </ng-container>
                        <ng-container *ngFor="let area of areas; index as i;">
                            <tr>
                                <td rowspan="2" class="first-left-col region-name" [class]="i==0 ? 'bold': ''">{{area}}</td>
                                <td class="bg-row third-left-col third-left-region-col">Share</td>
                                <ng-container *ngFor="let item of categories; index as j;">
                                    <td class="bg-row"><b>{{getShareOfCategory(marketShareByRegionData, area, item)}}</b></td>
                                </ng-container>
                            </tr>
                            <tr class="last-row">
                                <td class="third-left-col third-left-region-col">Diff.LY</td>
                                <ng-container *ngFor="let item of categories; index as j;">
                                    <td [class]="getVsLyOfCategory(marketShareByRegionData, area, item).indexOf('-') == 0 ? 'text-red': ''">{{getVsLyOfCategory(marketShareByRegionData, area, item)}}</td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </table>
                </div>
                <div class="d-flex flex-two-ends mt-4 hide-pdf-print">
                    <comment-button title1="VM Market Value Share by Area" [screen]="screen"
                    [users]="users" [chartId]="chartId" [chatIdDependency]="[{selectedWeek, state:{aggregateBy: aggregateBy ?? 'wtd'}}]"></comment-button>

                    <download-button>
                        <button ngbDropdownItem (click)="exportOTCMarketValueShareByRegion(true)">Download PNG</button>
                        <button ngbDropdownItem (click)="exportOTCMarketValueShareByRegion(false)">Download CSV</button>
                        <button ngbDropdownItem (click)="downloadAsPDF('VM Market Value Share by District')">Download PDF</button>
                    </download-button>
                </div>                
            </div>
            <!--FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG-->
            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>