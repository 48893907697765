import { Component, Renderer2, OnChanges, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import html2canvas from 'src/html2canvas';
import { IntageHelper } from 'src/app/components/pages/intage-vm/intage_helper';
import lodashClonedeep from 'lodash.clonedeep';
import { downloadAsPDF as _downloadAsPDF } from './../../../../helpers/pdf';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';
@Component({
  selector: 'intage-otc-market-size-vm-ccbji',
  templateUrl: './otc-market-size-vm-ccbji.component.html',
  styleUrls: ['./otc-market-size-vm-ccbji.component.scss']
})
export class IntageOtcMarketSizeVMCCBJIComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  @Input() users: any;
  //FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  weeks: any;
  categories: any;
  data: any;
  categoryColors: any;
  makerLogo: any;
  summaryRegions: any;
  summaryPackageTypes: any;
  otcSelectedRegion: any;
  otcSelectedPackageType: any;
  marketShareData2: any;
  groupMarketShareData2: any[];
  populated: boolean;
  CocacolaMaker: any;
  market_size: any;
  competitors_maker_list: any;
  aggregatedRegion: any;
  makers: any;
  aggregateBy: any;
  marketShareSelectedRegion: any;
  districts: any;
  chartId = "vm-market-value-share-by-category-ccbji";

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.marketShareSelectedRegion = ['CCBJI'];
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }     

  ngOnChanges() {
    if(!this.populated){
      this.otcSelectedRegion = ['CCBJI'];
      this.otcSelectedPackageType = ['TOTAL'];
      this.categoryColors = IntageHelper.CategoryColorsOTCByRegionVM();
      this.makerLogo = IntageHelper.MakerLogosVM();
      this.populated = true;
    }
    if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
      this.categories = IntageHelper.categoriesFilter();
      this.districts = IntageHelper.district();
      this.makers = IntageHelper.makers();
      this.summaryPackageTypes = IntageHelper.packageType();
      this.handleMarketShare2();
    }
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["otcSelectedRegion"] = [...this.otcSelectedRegion];
      window["otcSelectedPackageType"] = [...this.otcSelectedPackageType];
      window["marketShareSelectedRegion"] = [...this.marketShareSelectedRegion];

    } else {
      this.otcSelectedRegion = window["otcSelectedRegion"];
      this.otcSelectedPackageType = window["otcSelectedPackageType"];
      this.marketShareSelectedRegion = window["marketShareSelectedRegion"];
    }
  }

  quarterTransform(month: string): any {
    let monthOnly = month.substring(5,7);
    switch(monthOnly) {
      case '01':
      case '02':
      case '03':
        return month.substring(0,5)+'01';
      case '04':
      case '05':
      case '06':
        return month.substring(0,5)+'02';
      case '07':
      case '08':
      case '09':
        return month.substring(0,5)+'03';
      case '10':
      case '11':
      case '12':
        return month.substring(0,5)+'04';
    } 
  }
  
  handleMarketShare2(){
    let intage = lodashClonedeep(this.intage);
    let data = [];
    switch (this.aggregateBy) {
      case 'mtd':
        let month = this.intageMonth.find(y => y.week == this.selectedWeek).month;
        data = intage.filter(x => x.botter_sci == this.marketShareSelectedRegion[0] && (this.intageMonth.find(y => y.week == x.week).month == month) && x.week <= this.selectedWeek);  
        break;
      case 'qtd':
        let quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
        data = intage.filter(x => x.botter_sci == this.marketShareSelectedRegion[0] && (this.intageMonth.find(y => y.week == x.week).quarter == quarter) && x.week <= this.selectedWeek);
        break;
      case 'ytd':
        let year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
        data = intage.filter(x =>x.botter_sci == this.marketShareSelectedRegion[0] && (this.intageMonth.find(y => y.week == x.week).year == year) && x.week <= this.selectedWeek);
        break;
      default:
        data = intage.filter(x => x.week == this.selectedWeek && x.botter_sci == this.marketShareSelectedRegion[0]);
        break;
    }


    //let data = intage.filter(x=> x.week == this.selectedWeek &&  x.botter_sci == 'CCBJI');
    // data.map(x => {
    //   if (x.category == 'その他')
    //   {
    //     x.category = 'Other';
    //   }
    //   if (x.maker == 'KO')
    //   {
    //     x.maker = 'COCA-COLA';
    //   }
    // });

    // this.categories.map(x => {
    //   if (x.category == 'その他')
    //   {
    //     x.category = 'Other';
    //   }
    // })

    // this.makers.map(x => {
    //   if (x.maker == 'KO')
    //   {
    //     x.maker = 'COCA-COLA';
    //   }
    // })

    var total_market_category = [];
    this.categories.forEach(c => {
      var ty_value = 0;
      var ly_value = 0;
      var category_filter = data.filter(x => x.category == c); 
      category_filter.forEach(x => {
        ty_value += parseFloat(x.tyvalue);
        ly_value += parseFloat(x.lyvalue);
      });
      if (category_filter.length == 0)
      {
        ty_value = null;
        ly_value = null;
      }
      total_market_category.push(
        {category: c, tyvalue: ty_value, lyvalue: ly_value}
      );
    })

    var total_market_total_category_tyvalue = 0;
    var total_market_total_category_lyvalue = 0;
    total_market_category.forEach(x => {
      total_market_total_category_tyvalue += x.tyvalue;
      total_market_total_category_lyvalue += x.lyvalue;
    });

    this.market_size = [];
    this.categories.forEach(c => {
      if (c.toLowerCase() == 'total')
      {
        if (total_market_total_category_lyvalue == 0)
        {
          var vsLy = '-';
        }
        else if (total_market_total_category_tyvalue == 0)
        {
          var vsLy = '-100.0';
        }
        else 
        {
          var vsLy = ((total_market_total_category_tyvalue - total_market_total_category_lyvalue) / total_market_total_category_lyvalue * 100).toFixed(1);
        }
   
        let imp = 100.0.toFixed(1);
        this.market_size.push({week: this.selectedWeek, botter_sci: this.marketShareSelectedRegion[0], category: c, tyvalue: total_market_total_category_tyvalue, lyvalue: total_market_total_category_lyvalue, vsLy: vsLy, imp: imp});
      }
      else
      {
        let row_data = total_market_category.filter(x => x.category == c)[0];
        if (row_data.lyvalue == null || row_data.lyvalue == 0)
        {
          var vsLy = '-';
        }
        else if (row_data.tyvalue == null || row_data.tyvalue == 0)
        {
          var vsLy = '-100.0';
        }
        else
        {
          var vsLy = ((parseFloat(row_data.tyvalue) - parseFloat(row_data.lyvalue)) / row_data.lyvalue * 100).toFixed(1);
        }
        
        let imp = (row_data.tyvalue == null || total_market_total_category_tyvalue == 0) ? null : (parseFloat(row_data.tyvalue) / total_market_total_category_tyvalue * 100).toFixed(1);
        this.market_size.push({week: this.selectedWeek, botter_sci: this.marketShareSelectedRegion[0], category: c, tyvalue: row_data.tyvalue, lyvalue: row_data.lyvalue, vsLy: vsLy, imp: imp});
      }
    })


    this.marketShareData2 = [];

    this.categories.forEach(c => {
      this.makers.forEach(m => {
        if (c.toLowerCase() != 'total')
        {
          var maker_category_tyvalue = 0;
          var maker_category_lyvalue = 0;
          var maker_category_list = data.filter(x => x.category == c && x.maker == m.maker);
          maker_category_list.forEach(x => {
            maker_category_tyvalue += parseFloat(x.tyvalue);
            maker_category_lyvalue += parseFloat(x.lyvalue);
          })
          if (maker_category_list.length == 0)
          {
            maker_category_tyvalue = null;
            maker_category_lyvalue = null;
          }
          var tyvalue_marketsize = total_market_category.filter(x => x.category == c)[0]?.tyvalue;
          var lyvalue_marketsize = total_market_category.filter(x => x.category == c)[0]?.lyvalue;
          let share_ty_norounding =  (maker_category_tyvalue == null || tyvalue_marketsize == 0) ? null : (maker_category_tyvalue / parseFloat(tyvalue_marketsize) * 100);
          let share_ly_norounding = (maker_category_lyvalue == null || lyvalue_marketsize == 0) ? null : (maker_category_lyvalue / parseFloat(lyvalue_marketsize) * 100);
          let share_ty =  (maker_category_tyvalue == null || tyvalue_marketsize == 0) ? null : (maker_category_tyvalue / parseFloat(tyvalue_marketsize) * 100).toFixed(1);
          let diff_ly = (share_ty_norounding == null || share_ly_norounding == null) ? null : (share_ty_norounding - share_ly_norounding).toFixed(1);
          this.marketShareData2.push({week: this.selectedWeek, botter_sci: this.marketShareSelectedRegion[0], maker: m.maker, category: c, 
          tyvalue: maker_category_tyvalue, lyvalue: maker_category_lyvalue, share: share_ty, diff_ly: diff_ly});
        }       
      })
    })
    
    var market_size_total_category_tyvalue = 0;
    var market_size_total_category_lyvalue = 0;
    data.forEach(x => {
      market_size_total_category_tyvalue += parseFloat(x.tyvalue);
      market_size_total_category_lyvalue += parseFloat(x.lyvalue);
    });

    var maker_total_category = [];
    this.makers.forEach(m => {
      var ty_value = 0;
      var ly_value = 0;
      var maker_filter = data.filter(x => x.maker == m.maker); 
      maker_filter.forEach(x => {
        ty_value += parseFloat(x.tyvalue);
        ly_value += parseFloat(x.lyvalue);
      });
      if (maker_filter.length == 0)
      {
        ty_value = null;
        ly_value = null;
      }
      maker_total_category.push(
        {maker: m.maker, tyvalue: ty_value, lyvalue: ly_value}
      );
      let share_ty_norounding =  (ty_value == null || market_size_total_category_tyvalue == 0) ? null : (ty_value / market_size_total_category_tyvalue * 100);
      let share_ly_norounding = (ly_value == null || market_size_total_category_lyvalue == 0) ? null : (ly_value / market_size_total_category_lyvalue * 100);
      let share_ty =  (ty_value == null || market_size_total_category_tyvalue == 0) ? null : (ty_value / market_size_total_category_tyvalue * 100).toFixed(1);
      let diff_ly = (share_ty_norounding == null || share_ly_norounding == null) ? null : (share_ty_norounding - share_ly_norounding).toFixed(1);
      this.marketShareData2.push({week: this.selectedWeek, botter_sci: this.marketShareSelectedRegion[0], maker: m.maker, category: 'Total', tyvalue: ty_value, lyvalue: ly_value, share: share_ty, diff_ly: diff_ly});
    })

    this.competitors_maker_list = [];
    var competitors_maker_list = [];
    this.makers.forEach(x => {
      if (x.maker == 'COCA-COLA' || x.maker == 'Others' || x.maker == 'PokkaSapporo')
      {
        return;
      } 
      if (!competitors_maker_list.includes(x.maker))
      {
        competitors_maker_list.push(x.maker);
      }
    });

    competitors_maker_list.sort((a,b) => {
      if (a > b)
      {
        return 1;
      }
      return -1;
    });
    this.competitors_maker_list = this.makerLogo.filter(x => competitors_maker_list.includes(x.maker));

    let t1= this;
    setTimeout(() => {
      t1.ready.emit(true);
    });
  }

  public getCategoryColor(category: string){
    return IntageHelper.GetCategoryColor(category);
  }

  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }
  public getShareOfCategory(marketShareData2: any, maker: string, category: string){
    if (marketShareData2.filter(x => x.maker == maker && x.category == category)[0]?.share == null)
    {
      return '-';
    }
    return marketShareData2.filter(x => x.maker == maker && x.category == category)[0]?.share;
  }

  public getVsLyOfCategory(marketShareData2: any, maker: string, category: string){
    if (marketShareData2.filter(x => x.maker == maker && x.category == category)[0]?.diff_ly == null)
    {
      return '-';
    }
    return marketShareData2.filter(x => x.maker == maker && x.category == category)[0]?.diff_ly;
  }

  public getVsLyOfCategoryForMarketSize(market_size:any, category: string){
    if (market_size.filter(x => x.category == category)[0]?.vsLy == null)
    {
      return '-';
    }
    return market_size.filter(x => x.category == category)[0]?.vsLy;
  }

  public getImpOfCategory(market_size:any, category: string){
    if (market_size.filter(x => x.category == category)[0]?.imp == null)
    {
      return '-';
    }
    return market_size.filter(x => x.category == category)[0]?.imp;
  }

  public onAggregateChange(aggregateBy: string) {
    this.aggregateBy = aggregateBy;
    this.handleMarketShare2();
  }

  // FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  public exportOTCMarketSizeCCBJI(isImage: boolean) {
    const fileName = 'VM Market Value Share by Category CCBJI';
    if (isImage) {
      // FPT-ChienNX7 [2022/12/09][SENSE][1203444563290052] Fix bug image download incorrect
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];
      for (var i = 0; i < this.market_size.length; i++) {
        let category_data = this.market_size[i];
        data.push({
            week: category_data.week,
            aggregateBy: this.aggregateBy == null ? "wtd" : this.aggregateBy,
            region : this.otcSelectedRegion[0],
            packagetype: this.otcSelectedPackageType[0],
            maker: 'Market Size',
            category: category_data.category,
            ty: category_data.tyvalue,
            ly: category_data.lyvalue,
            share: '',
            diffLy: '',
            imp: category_data.imp,
            vsLy: category_data.vsLy
          });          
      }

      this.marketShareData2.forEach(x => {
        data.push({
          week: x.week,
          aggregateBy: this.aggregateBy == null ? "wtd" : this.aggregateBy,
          region : this.otcSelectedRegion[0],
          packagetype: this.otcSelectedPackageType[0],
          maker: x.maker,
          category: x.category,
          ty: x.tyvalue,
          ly: x.lyvalue,
          share: x.share,
          diffLy: x.diff_ly,
          imp: '',
          vsLy: ''
        }); 
      })
     
      new AngularCsv(data, fileName, {
        showLabels: true,
        headers: ["Week", "Aggregate By", "Region", "Package Type", "Maker", "Category", "TY_Value", "LY_Value", "Share", "Diff.LY", "IMP", "% vs LY"],
      });
    }
    this.showOptionDownload();
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-market-size-ccbji');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  onRemoveFilterShareRegion($event) {
    if(this.marketShareSelectedRegion.length == 0){
      this.marketShareSelectedRegion = ['CCBJI'];
      this.SaveOptionValueTemp(false);
    }
    if ($event.value === 'CCBJI') {
      this.marketShareSelectedRegion = [];
      return;
    }
  }
  onAddFilterShareRegion($event) {
    if ($event === 'CCBJI') {
      this.marketShareSelectedRegion = ['CCBJI'];
      return;
    }
    if(this.marketShareSelectedRegion.filter(x=> x != 'CCBJI').length == (this.districts.length-1)){
      this.marketShareSelectedRegion = ['CCBJI'];
    }else{
      this.marketShareSelectedRegion = this.marketShareSelectedRegion.filter(e => e !== 'CCBJI');;
    }
  }

  public onMarketShareRegionChange(){
    if(this.marketShareSelectedRegion.length == 0){
      this.marketShareSelectedRegion = ['CCBJI'];
    }
    this.handleMarketShare2();
  }

  @ViewChild('pdf_print_area_1', { static: false }) pdf_print_area_1: ElementRef;
  public downloadAsPDF(name) {
    return _downloadAsPDF(name, this.pdf_print_area_1.nativeElement)
  }


}
