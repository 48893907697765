import * as tsvParse from "d3-dsv";
import { environment } from 'src/environments/environment';
import { AnalyticsQueries } from 'src/app/model/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
export class IntageHelper 
{
    static CategoryColors(){
        return [
            {
            category: "TOTAL",
            displayName: "Total",
            color: "#8B90A3",
            index: 1
            },
            {
            category: "NST",
            displayName: "NST",
            color: "#93AFB9",
            index: 2
            },
            {
            category: "Coffee",
            displayName: "Coffee",
            color: "#C69C83",
            index: 3
            },
            {
            category: "Water",
            displayName: "Water",
            color: "#4DA2D6",
            index: 4
            },
            {
            category: "Juice",
            displayName: "Juice",
            color: "#FCCF4D",
            index: 5
            },
            {
            category: "Energy",
            displayName: "Energy",
            color: "#F08258",
            index: 6
            },
            {
            category: "Flavored SSD",
            displayName: "Flavors SSD",
            color: "#9A64A7",
            index: 7
            },
            {
            category: "Black Tea",
            displayName: "Black Tea",
            color: "#A09498",
            index: 8
            },
            {
            category: "Sports",
            displayName: "Sports",
            color: "#4FB9C1",
            index: 9
            },
            {
            category: "Cola SSD",
            displayName: "Cola",
            color: "#F40009",
            index: 10
            },
            {
            category: "Others",
            displayName: "Other",
            color: "#BCC3D1",
            index: 11
            },
            {
              category: "Adv. Hydration",
              displayName: "Adv. Hydration",
              color: "#4FB9C1",
              index: 12          
            }
        ];
    }
    static CategoryColorsOTCByRegion(){
        return [
            {
            category: "TOTAL",
            displayName: "Total",
            color: "#8B90A3",
            index: 1
            },
            {
            category: "NS Tea",
            displayName: "NST",
            color: "#93AFB9",
            index: 2
            },
            {
            category: "Coffee",
            displayName: "Coffee",
            color: "#C69C83",
            index: 3
            },
            {
            category: "Water",
            displayName: "Water",
            color: "#4DA2D6",
            index: 4
            },
            {
            category: "Juice",
            displayName: "Juice",
            color: "#FCCF4D",
            index: 5
            },
            {
            category: "Flavored SSD",
            displayName: "Flavors SSD",
            color: "#9A64A7",
            index: 6
            },
            {
            category: "Sports",
            displayName: "Sports",
            color: "#4FB9C1",
            index: 7
            },
            {
            category: "Energy",
            displayName: "Energy",
            color: "#F08258",
            index: 8
            },
            {
            category: "Cola SSD",
            displayName: "Cola",
            color: "#F40009",
            index: 9
            },
            {
            category: "Black Tea",
            displayName: "Black Tea",
            color: "#A09498",
            index: 10
            },
            {
            category: "Others",
            displayName: "Other",
            color: "#BCC3D1",
            index: 11
            }
        ];
    }
    static RegionOrders(){
      return [
        {
          region: "NORTH KANTO/SOUTH TOHOKU",
        index: 1
        },
        {
          region: "METROPOLITAN",
        index: 2
        },
        {
          region: "KANSAI/CHUBU",
        index: 3
        },
        {
          region: "KYUSHU/CHU-SHIKOKU",
        index: 4
        }
      ];
    }
    static ChannelOrders(){
        return [
            {
            channel: "SM",
            index: 1
            },
            {
            channel: "Drug",
            index: 2
            },
            {
            channel: "Discounter",
            index: 3
            },
            {
            channel: "CVS",
            index: 4
            },
            {
            channel: "Other OTC",
            index: 5
            }
        ];
    }

    static GetIntageDataFlash(http: HttpClient, week:string) : Promise<any>{
      return new Promise<any>((resolve, reject) => {
              let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.intageWeekDataFlash + "&week=" + week;
              let httpOptions: Object = {
                  headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                  responseType: 'text'
              }
              let t=this;
              http.get<any>(queryUrl, httpOptions)
                  .subscribe(
                  response => {
                      let tyPerWeek = [];
                      let intage = tsvParse.tsvParse('week	maker	category	channel	region	packageType	bottle	ty	ly	tyVolume	lyVolume\n' + response); 
                      for(var i=0;i<intage.length;i++){
                        let week = tyPerWeek.find(x=> x.week == intage[i].week);
                        if(week == null){
                            week = {
                            week: intage[i].week,
                            ty: parseFloat(intage[i].ty)
                            };
                            tyPerWeek.push(week);
                        }else{
                            week.ty += parseFloat(intage[i].ty);
                        }
                        let category = t.CategoryColors().find(x=> x.category == intage[i].category);
                        if(category != null){
                            intage[i].categoryIndex = category.index;
                        }else{
                            intage[i].categoryIndex = 100;
                        }
        
                        let channel = t.ChannelOrders().find(x=> x.channel == intage[i].channel);
                        if(channel != null){
                            intage[i].channelIndex = channel.index;
                        }else{
                            intage[i].channelIndex = 100;
                        }
                        let region = t.RegionOrders().find(x=> x.region == intage[i].region);
                        if(region != null){
                            intage[i].regionIndex = region.index;
                        }else{
                            intage[i].regionIndex = 100;
                        }
                      }
                      for(var i=0;i<tyPerWeek.length;i++){
                        if(tyPerWeek[i].ty > 0){
                            continue;
                        }
                        intage = intage.filter(x=> x.week != tyPerWeek[i].week);
                      }
      
                      intage.sort(t.otcCompare);                     
                      resolve(intage);
                  },
                  error => 
                  {
                    console.log(error);
                  }
                  );              
              });
          }

    static GetIntageAllDataFlash(http: HttpClient) : Promise<any>{
        return new Promise<any>((resolve, reject) => {
                    let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.intageAllDataFlash;
                    let httpOptions: Object = {
                        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                        responseType: 'text'
                    }
                    let t=this;
                    http.get<any>(queryUrl, httpOptions)
                        .subscribe(
                        response => {     
                            let tyPerWeek = [];                     
                            let intage = tsvParse.tsvParse('week	maker	category	channel	region	packageType	bottle	ty	ly	tyVolume	lyVolume\n' + response);
                            for(var i=0;i<intage.length;i++){
                              let week = tyPerWeek.find(x=> x.week == intage[i].week);
                              if(week == null){
                                  week = {
                                  week: intage[i].week,
                                  ty: parseFloat(intage[i].ty)
                                  };
                                  tyPerWeek.push(week);
                              }else{
                                  week.ty += parseFloat(intage[i].ty);
                              }
                              let category = t.CategoryColors().find(x=> x.category == intage[i].category);
                              if(category != null){
                                  intage[i].categoryIndex = category.index;
                              }else{
                                  intage[i].categoryIndex = 100;
                              }
              
                              let channel = t.ChannelOrders().find(x=> x.channel == intage[i].channel);
                              if(channel != null){
                                  intage[i].channelIndex = channel.index;
                              }else{
                                  intage[i].channelIndex = 100;
                              }
                              let region = t.RegionOrders().find(x=> x.region == intage[i].region);
                              if(region != null){
                                  intage[i].regionIndex = region.index;
                              }else{
                                  intage[i].regionIndex = 100;
                              }
                            }
                            for(var i=0;i<tyPerWeek.length;i++){
                              if(tyPerWeek[i].ty > 0){
                                  continue;
                              }
                              intage = intage.filter(x=> x.week != tyPerWeek[i].week);
                            }
            
                            intage.sort(t.otcCompare);                          
                            resolve(intage);
                        },
                        error => 
                        {
                          console.log(error);
                        }
                        );              
                    });
                }
    
    static GetIntageMonthFromWeek(http: HttpClient, weeklist) : Promise<any>{
        return new Promise<any>((resolve, reject) => {
                    let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.intageMonthFromWeek + "&weeklist=" + weeklist;
                    let httpOptions: Object = {
                        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                        responseType: 'text'
                    }
                    let t=this;
                    http.get<any>(queryUrl, httpOptions)
                        .subscribe(
                        response => {
                            let intageMonth = tsvParse.tsvParse('week	month	quarter	year\n' + response);
                            resolve(intageMonth);
                        },
                        error => 
                        {
                          console.log(error);
 
                        }
                        );                
                });
            }  

    static GetIntageDataDashboard(http: HttpClient) : Promise<any>{
      return new Promise<any>((resolve, reject) => {
              let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.intageAllDataDashboard;
              let httpOptions: Object = {
                  headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                  responseType: 'text'
              }
              let t=this;
              http.get<any>(queryUrl, httpOptions)
                  .subscribe(
                  response => {
                      let intage = tsvParse.tsvParse('week	maker	category	channel	region	packageType	bottle	ty	ly	tyVolume	lyVolume\n' + response);
                      resolve(intage);
                  },
                  error => 
                  {
                    console.log(error);
                    
                  }
                  );              
              });
          }
    
    static GetIntageWeekData(http: HttpClient) : Promise<any>{
      return new Promise<any>((resolve, reject) => {
              let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.intageWeekData;
              let httpOptions: Object = {
                  headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                  responseType: 'text'
              }
              let t=this;
              http.get<any>(queryUrl, httpOptions)
                  .subscribe(
                  response => {
                    let intageweek = tsvParse.tsvParse('week\n' + response);
                    resolve(intageweek);
                  },
                  error => 
                  {
                    console.log(error);
                    
                  }
                  );              
      });
    }

    static GetIntageDashboardWeekData(http: HttpClient) : Promise<any>{
      return new Promise<any>((resolve, reject) => {
              let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.intageDashboardWeekData;
              let httpOptions: Object = {
                  headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                  responseType: 'text'
              }
              let t=this;
              http.get<any>(queryUrl, httpOptions)
                  .subscribe(
                  response => {
                    let intageweek = tsvParse.tsvParse('week\n' + response);
                    resolve(intageweek);
                  },
                  error => 
                  {
                    console.log(error);
                    
                  }
                  );              
      });
    }

    static otcCompare(a, b) {
        if (a.categoryIndex < b.categoryIndex){
        return -1;
        }
        if (a.categoryIndex > b.categoryIndex){
        return 1;
        }
        if (a.channelIndex < b.channelIndex){
        return -1;
        }
        if (a.channelIndex > b.channelIndex){
        return 1;
        }
        if (a.regionIndex < b.regionIndex){
        return -1;
        }
        if (a.regionIndex > b.regionIndex){
        return 1;
        }
        return 0;
    }

    static MakerLogos(){
        return [
            {
              maker : "COCA-COLA",
              logo: "assets/img/maker/Coca-Cola.png",
              index: 1,
              checked: true
            },
            {
              maker : "SUNTORY",
              logo: "assets/img/maker/Suntory.png",
              index: 2,
              checked: false
            },
            {
              maker : "KIRIN",
              logo: "assets/img/maker/Kirin.png",
              index: 3,
              checked: false
            },
            {
              maker : "ASAHI",
              logo: "assets/img/maker/Asahi.png",
              index: 4,
              checked: false
            },
            {
              maker : "ITOEN",
              logo: "assets/img/maker/Itoen.png",
              index: 5,
              checked: false
            },
            {
              maker : "Red Bull",
              logo: "assets/img/maker/Red-Bull-logo.png",
              index: 6,
              checked: false
            }
          ];
    }

    static GetMakerColor(maker: string) {
      let allColors = ['#0000FF',  
      '#00FF00', '#FF00FF', '#FFC0CB', '#FFA500', 
      '#A52A2A', '#800000', '#808080', '#C0C0C0', '#008000','#808000',
      '#7FFD4','#000000','#FFFFFF'];
      if (maker === "COCA-COLA") {
        return "#F40009";
      }
      if (maker === "KIRIN") {
        return "#FFA500";
      }
      if (maker === "CVS") {
        return "#0000FF";
      }
      if (maker === "ASAHI") {
        return "#004099";
      }        
      if (maker === 'SUNTORY')
      {
        return "#65C6DE";
      }
      if (maker === 'ITOEN')
      {
        return "#009E41";
      }
      if (maker === 'Red Bull')
      {
        return "#008080";
      }
      if (maker === 'DYDO')
      {
        return "#000000"
      }
      const stringToColour = function (str) {
        let hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (var i = 0; i < 3; i++) {
          var value = (hash >> (i * 8)) & 0xFF;
          colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
      }
      return stringToColour(maker);
    }

    static SmddChannels(){
        return [
            {
              channel: "OTC Total",
              index: 7,
              child: false
            },
            {
              channel: "SMDD",
              index: 1,
              childChannels: [
                "SM",
                "Drug",
                "Discounter"
              ],
              child: false
            },
            {
              channel: "SM",
              index: 2,
              child: true
            },
            {
              channel: "Drug",
              index: 3,
              child: true
            },
            {
              channel:  "Discounter",
              index: 4,
              child: true
            },
            {
              channel: "CVS",
              index: 5,
              child: false
            },
            {
              channel: "Other OTC",
              index: 6,
              child: false
            }
          ];
    }

    static PopulateCommonData(intage: any, selectedWeek: string){
        let otcData = [];
        let selectedWeekInt = parseInt(selectedWeek.replace('-','').replace('-',''));
        for(var i = 0;i<intage.length;i++){
            let row = intage[i];
            if(parseInt(row.week.replace('-','').replace('-','')) > selectedWeekInt){
                continue;
            }
            else {
                otcData.push(row);
            }
        }

        let otcChannels = [{
            channel: "OTC Total",
            checked: true
          },
          {
            channel: "SMDD",
            checked: false
          }
        ];
        for(var i=0;i<otcData.length;i++){
            let row = otcData[i];
            if(otcChannels.find(x=> x.channel == row.channel) == null){
                otcChannels.push({
                    channel: row.channel,
                    checked: false
                });
            }
        }

        var reorder_otcChannels = [{
          channel: "OTC Total",
          checked: true
        },
        {
          channel: "SMDD",
          checked: false
        }
      ];

        let SM_channel = otcChannels.find(x => x.channel == "SM");
        if (SM_channel != null)
        {
          reorder_otcChannels.push(SM_channel);
        }

        let Drug_channel = otcChannels.find(x => x.channel == "Drug");
        if (Drug_channel != null)
        {
          reorder_otcChannels.push(Drug_channel);
        }

        let Discounter_channel = otcChannels.find(x => x.channel == "Discounter");
        if (Discounter_channel != null)
        {
          reorder_otcChannels.push(Discounter_channel);
        }

        otcChannels.forEach(x => {
          if (x.channel != "OTC Total" && x.channel != "SMDD" && x.channel != "Drug" && 
          x.channel != "Discounter" && x.channel != "SM" )
          {
            reorder_otcChannels.push(x);
          }
        })




        let categories = [{
            category: 'Total'
          }
        ];
        let summaryRegions = ['CCBJI Territory'];
        let makers = [];
        let summaryPackageTypes = ['All'];
        let categoriesFilter = [];
        for(var i=0;i<intage.length;i++){
            let row = intage[i];
            if(categories.find(x=> x.category == row.category) == null){
              categories.push({
                category: row.category
              });
            }
            var summaryRegion = summaryRegions.find(x=> x == row.region);
            if(summaryRegion == null){
              summaryRegions.push(row.region);
            }
            let maker = makers.find(x=> x.maker == row.maker);
            if(maker == null){
                let makerLogo = this.MakerLogos().find(x=> x.maker == row.maker);
                makers.push({
                    maker: row.maker,
                    index: makerLogo != null ? makerLogo.index: 100
                });
            }
            if(row.packageType == ''){
              continue;
            }
            var summaryPackageType = summaryPackageTypes.find(x=> x == row.packageType);
            if(summaryPackageType == null){
              summaryPackageTypes.push(row.packageType);
            }
        }
        categoriesFilter = categories.slice();
        makers.sort((a, b) => a.index > b.index ? 1 : -1);
        return {
            otcChannels: reorder_otcChannels,
            categories: categories,
            summaryRegions: summaryRegions,
            makers: makers,
            summaryPackageTypes: summaryPackageTypes,
            categoriesFilter: categoriesFilter
        };
    }

    static GetCategoryColor(category: string){
        let cate = this.CategoryColors().find(x=> x.category == category);
        if(cate == null){
          return "#8B90A3";
        }
        return cate.color;
    }

    static GetCategoryDisplayName(category: string){
        let cate = this.CategoryColors().find(x=> x.category == category);
        if(cate == null){
          return category;
        }
        return cate.displayName;
    }

    static GetShareOfCategory(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "-";
      }
      return totalCategory.share;
    }

    static GetShareVolumeOfCategory(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "-";
      }
      return totalCategory.shareVolume;
    }

    static GetVsLyOfCategory(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "-";
      }
      return totalCategory.vsLy;
    }
    static GetVsLyVolumeOfCategory(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "-";
      }
      
      return totalCategory.vsLyVolume;
    }
    static GetVsLyVolumeOfCategoryForMarketSize(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "-";
      }
      
      return totalCategory.vsLyVolume;
    }
    //FPT-TruongDV19 Update default value for null case.
    static GetVsLyOfCategoryForMarketSize(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "-";
      }
      
      return totalCategory.vsLy;
    }

    static GetImpOfCategory(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "-";
      }
      return totalCategory.imp;
    }

    static Categories()
    {
      return ['Total', 'NST', 'Coffee', 'Water', 'Juice', 'Energy', 'Flavored SSD', 'Black Tea', 'Adv. Hydration', 'Cola SSD', 'Others'];
    }

    static PackageType()
    {
      return ['All', 'SS', 'MS'];
    }

    static Regions()
    {
      return ['CCBJI Territory', 'NORTH KANTO/SOUTH TOHOKU', 'METROPOLITAN', 'KANSAI/CHUBU', 'KYUSHU/CHU-SHIKOKU'];
    }

    static OTCChannels()
    {
      return [
        {
            "channel": "OTC Total",
            "checked": true
        },
        {
            "channel": "SMDD",
            "checked": false
        },
        {
            "channel": "SM",
            "checked": false
        },
        {
            "channel": "Drug",
            "checked": false
        },
        {
            "channel": "Discounter",
            "checked": false
        },
        {
            "channel": "CVS",
            "checked": false
        },
        {
            "channel": "Other OTC",
            "checked": false
        }
      ]
    }

    static Makers()
    {
      return [
        {
            "maker": "COCA-COLA",
            "index": 1
        },
        {
            "maker": "SUNTORY",
            "index": 2
        },
        {
            "maker": "KIRIN",
            "index": 3
        },
        {
            "maker": "ASAHI",
            "index": 4
        },
        {
            "maker": "ITOEN",
            "index": 5
        },
        {
            "maker": "OTHER",
            "index": 100
        }
      ]
    }
} 