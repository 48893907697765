import { environment } from 'src/environments/environment';

export const tokens = {
    accessToken: 'accessToken'
}

export const apiUrl = environment.snowflakeProxyV2;

export const AnalyticsQueries = {
  lastUpdated : `lastUpdated`,
  lastUpdatedIntageOTC : `lastUpdatedIntageOTC`,
  lastUpdatedIntageVM : `lastUpdatedIntageVM`,
  lastUpdatedSE: 'selastupdated',
  lastUpdatedTraxReport: 'photorecognitionlastupdated',
  percentPerformance:`percentPerformance`,
  intageAllData: `intageAllData`,
  intageAllDataFlash: `intageAllDataFlash`,
  intageWeekDataFlash: `intageWeekDataFlash`,
  intageAllDataDashboard: `intageAllDataDashboard`,
  intageMonthFromWeek: `intageMonthFromWeek`,
  intageWeekData: `intageWeekData`,
  intageDashboardWeekData: `intageDashboardWeekData`,
  intageVMWeekData: `intagevmWeekData`,
  intageVMAllData: `intagevmalldata`,
  dailySales: `dailySales`,
  monthlySales: `monthlySales`,
  monthlyFullSales: `monthlyFullSales`,
  percentPerformanceHist:`percentPerformance_hist`,
  dailySalesHist: "dailySales_hist",
  monthlySalesHist: 'monthlySales_hist',
  monthlyFullSalesHist: "monthlyFullSales_hist",
  wrong411Channels: ['1000', '2000', '3500', '4000','5000',  '6000', '7100', '8000', '9999'],
  wrongNullSaleAreaChannelLev3: ['112', '122', '123', '124', '131', '132', '211', '212', '213', '214', '215', '221', '222', '223', '311', '321', '322'],
  wrongChannelLev331332SaleArea: ['1000', '2000', '3500', '4000', '6000', '7100', '8000', '9100', '9999'],
  wrongChannelLev131132SaleArea: ['1000', '2000', '3500', '4000', '6000', '7100', '8000', '9100'],
  wrongNullDistrictCodeRegionCode: ['111', '112', '121', '122', '123', '124', '132', '211', '212', '213', '214', '215', '221', '222', '223', '311', '321', '322'],
  wrong9999DistrictCode: ['111', '112', '121', '122', '123', '124', '131', '132', '211', '212', '213', '214', '215', '221', '222', '223', '311', '321', '322'],
  wrongChannelCodeOther: ['131', '331', '332'],
  wrongChannelCodeAlcohol: ['111', '112', '121', '122', '123', '124', '131', '132', '211', '212', '213', '214', '215', '221', '222', '223', '311', '321', '322'],


  progressByDistrict: "progressbydistrict",
  progressByRoute: "progressByRoute",
  progressByTimeline: 'progresstimeline',

  progressTimelineByWeek: "progressTimelineByWeek",
  progressTimelineByDay: "progressTimelineByDay",
  progressTimelineByMonth: "progressTimelineByMonth",
  progressTimelineByQuarter: "progressTimelineByQuarter",
  progressTimelineByYear: "progressTimelineByYear",
  progressAllFilterOptions: "progressbydistrictfilter",
  progressDistrictMasterData : "progressDistrictMasterData",

  hqProgressLeve1BySaleDate : "hqProgressLeve1BySaleDate",
  hqProgressLeve2BySaleDate : "hqProgressLeve2BySaleDate",
  hqprogressnationallocalSaleDate : "hqprogressnationallocal",
  hqProgressallfilteroptions : "hqprogressfilter",
  hqProgressTimeline : "hqprogresstimeline",
  hqProgressTimelineBySaleDate : "hqprogresstimelinebysaledate",
  hqProgressTimelineByDay : "hqprogresstimelinebyday",
  hqProgressTimelineByWeek : "hqprogresstimelinebyweek",
  hqProgressTimelineByMonth : "hqprogresstimelinebymonth",
  hqProgressTimelineByQuarter : "hqprogresstimelinebyquater",
  hqProgressTimelineByYear : "hqprogresstimelinebyyear",
  hqProgressListYears : "hqprogresslistyear",

  areaSalesRanking: "progressrankingareasale",
  areaSalesRankingCSV: "progressrankingareasalecsv",
  progressRankingAllFilterOptions : "progressrankingfilter"
}

/**
 * @author FPT-HoiLT2
 * @Desciption [2022/10/19][SENSE][1202866927297748] SENSE SKU & Customer view development
 */
export const SKUQueries = {
  allData: "SKUallData",
  allDataGroupByColumns: "SKUallDataGroupByColumns",
  countData: "SKUcountData",
  countDataGroupByColumns: "SKUcountDataGroupByColumns",
  salesArea: "SKUsalesArea",
  salesDistrict: "SKUsalesDistrict",
  channelLevel1: 'SKUchannelLevel1',
  janCode: "SKUjanCode",
  productNameJp: "SKUproductNameJp",
  brandName: "SKUbrandName",
  category: "SKUcategory",
  packageLv1: "SKUpackageLv1",
  container: 'SKUcontainer',
  salesAreaTosalesDistrict: 'SKUsalesAreaTosalesDistrict',
  groupcategorypackagecontainer: 'SKUgroupcategorypackagecontainer',
  groupBrandnameCategoryProductNameJp: 'SKUgroupBrandnameCategoryProductNameJp'
}

/**
 * @author FPT-HoiLT2
 * @Desciption [2023/02/27][SENSE][1202866927297748] SENSE SKU & Customer view development
 */
export const CustomerQueries = {
  allData: "CustomerallData",
  countData: "CustomercountData",
  salesArea: "CustomersalesArea",
  salesDistrict: "CustomersalesDistrict",
  salesAreaTosalesDistrict: 'CustomersalesAreaTosalesDistrict',
  categoryData: "CustomerCategory",
  channelLevel1: "CustomerchannelLevel1",
  channelLevel2: "CustomerchannelLevel2",
  channelLevel3: "CustomerchannelLevel3",
  channelLevel1toLevel2toLevel3: "CustomerchannelLevel1toLevel2toLevel3",
  accountLevel2: "CustomeraccountLevel2",
  accountLevel3: "CustomeraccountLevel3",
  allDataGroupByColumns: "CustomerallDataGroupByColumns",
  countDataGroupByColumns: "CustomercountDataGroupByColumns",
  hqprogressleve1 : "hqprogressleve1",
  hqprogressleve2 : "hqprogressleve2"
}

/**
 * @author SOSC
 */
export const SkuDeepdiveQueries = {
  calendarFilter: 'skudeepdivecalendarfilter',
  areaFilter    : 'skudeepdiveareafilter',
  accountFilter : 'skudeepdiveaccountfilter',
  productFilter : 'skudeepdiveproductfilter',
  byCategory    : 'skudeepdivebycategory',
  byBrand       : 'skudeepdivebybrand',
  byPackage     : 'skudeepdivebypackage',
  bySKU         : 'skudeepdivebysku',
  byTimeline    : 'skudeepdivetimeline',
}


export const AreaDeepdiveQueries = {
  calendarFilter: 'areasalesdeepdivecalendarfilter',
  areaFilter    : 'areasalesdeepdiveareafilter',
  accountFilter : 'areasalesdeepdiveaccountfilter',
  productFilter : 'areasalesdeepdiveproductfilter',
  byArea        : 'areasalesdeepdivebyarea',
  byChannel     : 'areasalesdeepdivebychannel',
  byRoute       : 'areasalesdeepdivebyroutebd',
  byDealer      : 'areasalesdeepdivebydealer',
  byTimeline    : 'areasalesdeepdivetimeline',
}

export const FaceshareQueries = {
  filter           : 'facesharefilter',
  byMakerAreaFilter: 'facesharebymakerfilter',
  byAccountFilter  : 'facesharebyaccountfilter',
  byMarker : 'facesharebymakercategory',
  byArea   : 'facesharebyarea',
  byAccount: 'facesharebyaccount',
  byMakerAreaCSV : 'facesharebymakercategorycsv',
  byAccountCSV   : 'facesharebyaccountcsv',
  bySkuCSV       : 'facesharebyskucsv',
  byAccountSkuCSV: 'facesharebyaccountbyskucsv',
};

export const PocQueries = {
  filter          : 'pocfilter',
  byCategoryFilter: 'pocbycategoryfilter',
  byAccountFilter : 'pocbyaccountfilter',
  byCategory      : 'pocbycategory',
  byAccount       : 'pocbyaccount',
  byCategoryCSV   : 'pocbycategorycsv',
  byAccountCSV    : 'pocbyaccountcsv'
}

/**
 * @author FPT-HoiLT2
 * @Desciption [2022/10/19][SENSE][1202866927297748] SENSE SKU & Customer view development
 */
export const APP_CONFIG = {
  paginationSize: 100,
  selectLazyLoadSize: 10,
};

/**
 * @author SOSC
 * @Desciption [2024/01/24][SENSE]
 */
export const FILTER_CONFIGS = {
  ADD_MORE_FILTER: {
    VISIBLE_COUNT: 4,
    DEFAULT: {
      CHANNEL_LEV1_NAME : 'CHANNEL_LEV1_NAME',
      SALES_HQ_LEV1_NAME: 'SALES_HQ_LEV1_NAME',
      KAM_DEPT_LV2_NAME : 'KAM_DEPT_LV2_NAME',
      CATEGORY_NAME_EN  : 'CATEGORY_NAME_EN',
      CHANNEL_LEV2_NAME : 'CHANNEL_LEV2_NAME',
      CHANNEL_LEV3_NAME : 'CHANNEL_LEV3_NAME',
      SALES_HQ_LEV2_NAME: 'SALES_HQ_LEV2_NAME',
      SALES_HQ_LEV3_NAME: 'SALES_HQ_LEV3_NAME',
      SALES_CENTER_NAME : 'SALES_CENTER_NAME',
      SALES_ROUTE_NAME  : 'SALES_ROUTE_NAME',
      SALES_CONTROL_BASE_ROUTE_CODE: 'SALES_CONTROL_BASE_ROUTE_CODE',
      COM_DIST_SALES_REP_NAME: 'COM_DIST_SALES_REP_NAME',
      DEALER_NAME       : 'DEALER_NAME',
      KAM_DEPT_LV3_NAME : 'KAM_DEPT_LV3_NAME',
      KAM_DEPT_LV4_NAME : 'KAM_DEPT_LV4_NAME',
      KAM_CONTACT_PERSON_NAME: 'KAM_CONTACT_PERSON_NAME',
      ACCOUNT_LEV2_NAME_EN: 'ACCOUNT_LEV2_NAME_EN',
      ACCOUNT_LEV3_NAME_EN: 'ACCOUNT_LEV3_NAME_EN',
      SUBCATEGORY_NAME_EN : 'SUBCATEGORY_NAME_EN',
      BRAND_NAME_EN        : 'BRAND_NAME_EN',
      PACKAGE_LEV1_NAME_EN : 'PACKAGE_LEV1_NAME_EN',
      PACKAGE_LEV2_NAME_EN : 'PACKAGE_LEV2_NAME_EN',
      PACKAGE_DTL_NAME: 'PACKAGE_DTL_NAME',
      CHANNEL_NL: 'CHANNEL_NL',
      SALES_CONTROL_BASE_NAME: 'SALES_CONTROL_BASE_NAME'
    },
    GROUP_NAME: {
      calendarFilter: 'calendarFilter',
      areaFilter: 'areaFilter',
      accountFilter: 'accountFilter',
      productFilter: 'productFilter',
    },
    KEY_NAME_AREA_DEEPDIVE:{
      CHANNEL_LEV1_NAME : 'CHANNEL_LEV1_NAME',
      CHANNEL_LEV3_NAME : 'CHANNEL_LEV3_NAME',
      SALES_HQ_LEV2_NAME: 'SALES_HQ_LEV2_NAME',
      CATEGORY_NAME_EN  : 'CATEGORY_NAME_EN',
      CHANNEL_LEV2_NAME : 'CHANNEL_LEV2_NAME',
      SALES_HQ_LEV1_NAME: 'SALES_HQ_LEV1_NAME',
      KAM_DEPT_LV2_NAME : 'KAM_DEPT_LV2_NAME',
      SALES_HQ_LEV3_NAME: 'SALES_HQ_LEV3_NAME',
      SALES_CENTER_NAME : 'SALES_CENTER_NAME',
      SALES_ROUTE_NAME  : 'SALES_ROUTE_NAME',
      SALES_CONTROL_BASE_ROUTE_CODE: 'SALES_CONTROL_BASE_ROUTE_CODE',
      COM_DIST_SALES_REP_NAME: 'COM_DIST_SALES_REP_NAME',
      DEALER_NAME       : 'DEALER_NAME',
      KAM_DEPT_LV3_NAME : 'KAM_DEPT_LV3_NAME',
      KAM_DEPT_LV4_NAME : 'KAM_DEPT_LV4_NAME',
      KAM_CONTACT_PERSON_NAME: 'KAM_CONTACT_PERSON_NAME',
      ACCOUNT_LEV2_NAME_EN: 'ACCOUNT_LEV2_NAME_EN',
      ACCOUNT_LEV3_NAME_EN: 'ACCOUNT_LEV3_NAME_EN',
      SUBCATEGORY_NAME_EN : 'SUBCATEGORY_NAME_EN',
      BRAND_NAME_EN        : 'BRAND_NAME_EN',
      PACKAGE_LEV1_NAME_EN : 'PACKAGE_LEV1_NAME_EN',
      PACKAGE_LEV2_NAME_EN : 'PACKAGE_LEV2_NAME_EN',
      PACKAGE_DTL_NAME: 'PACKAGE_DTL_NAME',
      CHANNEL_NL: 'CHANNEL_NL',
      SALES_CONTROL_BASE_NAME: 'SALES_CONTROL_BASE_NAME'
    },
    COLLECTIONS: {
      SKU_DEEPDIVE: [
        'CHANNEL_LEV1_NAME',
        'SALES_HQ_LEV2_NAME',
        'CHANNEL_LEV3_NAME',
        'CATEGORY_NAME_EN',
        'CHANNEL_LEV2_NAME',
        'SALES_HQ_LEV1_NAME',
        'SALES_HQ_LEV3_NAME',
        'SALES_CENTER_NAME',
        'SALES_ROUTE_NAME',
        'COM_DIST_SALES_REP_NAME',
        'DEALER_NAME',
        'KAM_DEPT_LV2_NAME',
        'KAM_DEPT_LV3_NAME',
        'KAM_DEPT_LV4_NAME',
        'KAM_CONTACT_PERSON_NAME',
        'ACCOUNT_LEV2_NAME_EN',
        'ACCOUNT_LEV3_NAME_EN',
        'SUBCATEGORY_NAME_EN',
        'BRAND_NAME_EN',
        'PACKAGE_LEV1_NAME_EN',
        'PACKAGE_LEV2_NAME_EN',
        'PACKAGE_DTL_NAME'
      ],
      AREA_DEEPDIVE: [
        'CHANNEL_LEV1_NAME',
        'CHANNEL_LEV3_NAME',
        'SALES_HQ_LEV2_NAME',
        'CATEGORY_NAME_EN',
        'CHANNEL_LEV2_NAME',
        'SALES_HQ_LEV1_NAME',
        'KAM_DEPT_LV2_NAME',
        'SALES_HQ_LEV3_NAME',
        'SALES_CENTER_NAME',
        'SALES_ROUTE_NAME',
        'KAM_DEPT_LV3_NAME',
        'KAM_DEPT_LV4_NAME',
        'KAM_CONTACT_PERSON_NAME',
        'ACCOUNT_LEV2_NAME_EN',
        'ACCOUNT_LEV3_NAME_EN',
        'SUBCATEGORY_NAME_EN',
        'BRAND_NAME_EN',
        'PACKAGE_LEV1_NAME_EN',
        'PACKAGE_LEV2_NAME_EN',
      ],
      DISTRICT_PROGRESS: [
        'SALES_HQ_LEV2_NAME',
        'CHANNEL_LEV1_NAME',
        'CHANNEL_LEV3_NAME',
        'CHANNEL_NL',
        'SALES_HQ_LEV1_NAME',
        'SALES_HQ_LEV3_NAME',
        'SALES_CENTER_NAME',
        'SALES_CONTROL_BASE_NAME',
        'SALES_CONTROL_BASE_ROUTE_CODE',
        'CHANNEL_LEV2_NAME'
      ],
      DISTRICT_PROGRESS_BY_ROUTE: [
        'SALES_HQ_LEV2_NAME',
        'SALES_HQ_LEV3_NAME',
        'CHANNEL_LEV1_NAME',
        'CHANNEL_LEV3_NAME',
        'CHANNEL_NL',
        'SALES_HQ_LEV1_NAME',
        'SALES_CENTER_NAME',
        'SALES_CONTROL_BASE_NAME',
        'SALES_CONTROL_BASE_ROUTE_CODE',
        'CHANNEL_LEV2_NAME'
      ]
    }
  },
  DISTRICT_PROGRESS: {
    SALES_HQ_LEV1_NAME: 'SALES_HQ_LEV1_NAME',
    SALES_HQ_LEV2_NAME: 'SALES_HQ_LEV2_NAME',
    SALES_HQ_LEV3_NAME: 'SALES_HQ_LEV3_NAME',
    SALES_CENTER_NAME : 'SALES_CENTER_NAME',
    SALES_CONTROL_BASE_NAME: 'SALES_CONTROL_BASE_NAME',
    SALES_CONTROL_BASE_ROUTE_CODE: 'SALES_CONTROL_BASE_ROUTE_CODE',
    CHANNEL_LEV1_NAME: 'CHANNEL_LEV1_NAME',
    CHANNEL_LEV2_NAME: 'CHANNEL_LEV2_NAME',
    CHANNEL_LEV3_NAME: 'CHANNEL_LEV3_NAME'
  },
  HQ_PROGRESS: {
    SALES_HQ_LEV1_NAME: 'SALES_HQ_LEV1_NAME',
    SALES_HQ_LEV2_NAME: 'SALES_HQ_LEV2_NAME',
    CHANNEL_LEV1_NAME: 'CHANNEL_LEV1_NAME',
    CHANNEL_LEV2_NAME: 'CHANNEL_LEV2_NAME',
    CHANNEL_LEV3_NAME: 'CHANNEL_LEV3_NAME',
    CHANNEL_NL: 'CHANNEL_NL' 
  },
  SKU_DEEPDIVE_DATE: {
    DATE_TYPE_NAME : 'DATE_TYPE_NAME',
    SALES_DATE_NAME: 'SALES_DATE_NAME',
  },
}

export const FILTER_CONFIGS_HQ = {
  ADD_MORE_FILTER: {
    COMMONS: {
      GROUP_NAME: {
        calendarFilter: 'calendarFilter',
        areaFilter    : 'areaFilter',
        accountFilter : 'accountFilter',
        productFilter : 'productFilter',
      },
      KEY_NAME: {
        CHANNEL_LEV1_NAME : 'CHANNEL_LEV1_NAME',
        CHANNEL_LEV1_NAME_NL: 'CHANNEL_LEV1_NAME_NL', // Only apply for HQProgress | Progress by National/Local - https://app.asana.com/0/1207724756986819/1208475203268684/f
        SALES_HQ_LEV1_NAME: 'SALES_HQ_LEV1_NAME',
        KAM_DEPT_LV2_NAME : 'KAM_DEPT_LV2_NAME',
        CATEGORY_NAME_EN  : 'CATEGORY_NAME_EN',
        CHANNEL_LEV2_NAME : 'CHANNEL_LEV2_NAME',
        CHANNEL_LEV3_NAME : 'CHANNEL_LEV3_NAME',
        SALES_HQ_LEV2_NAME: 'SALES_HQ_LEV2_NAME',
        SALES_HQ_LEV3_NAME: 'SALES_HQ_LEV3_NAME',
        CHANNEL_NL : 'CHANNEL_NL',
        SALES_CENTER_NAME : 'SALES_CENTER_NAME',
        SALES_CONTROL_BASE_ROUTE_CODE  : 'SALES_CONTROL_BASE_ROUTE_CODE',
        SALES_CONTROL_BASE_NAME: 'SALES_CONTROL_BASE_NAME',
        DEALER_NAME       : 'DEALER_NAME',
        KAM_DEPT_LV3_NAME : 'KAM_DEPT_LV3_NAME',
        KAM_DEPT_LV4_NAME : 'KAM_DEPT_LV4_NAME',
        KAM_CONTACT_PERSON_NAME: 'KAM_CONTACT_PERSON_NAME',
        ACCOUNT_LEV2_NAME_EN: 'ACCOUNT_LEV2_NAME_EN',
        ACCOUNT_LEV3_NAME_EN: 'ACCOUNT_LEV3_NAME_EN',
        SUBCATEGORY_NAME_EN : 'SUBCATEGORY_NAME_EN',
        BRAND_NAME_EN        : 'BRAND_NAME_EN',
        PACKAGE_LEV1_NAME_EN : 'PACKAGE_LEV1_NAME_EN',
        PACKAGE_LEV2_NAME_EN : 'PACKAGE_LEV2_NAME_EN',
        PACKAGE_DTL_NAME: 'PACKAGE_DTL_NAME'
      },
    },
    HQ_PROGRESS: {
      LABEL: 'HQ_PROGRESS',
      VISIBLE_COUNT: 4,
      COLLECTIONS: [
        'SALES_HQ_LEV1_NAME',
        'CHANNEL_LEV1_NAME_NL',
        'SALES_HQ_LEV2_NAME',
        'CHANNEL_LEV1_NAME',
        'CHANNEL_LEV2_NAME',
        'CHANNEL_LEV3_NAME',
        'CHANNEL_NL'
      ]
    },
    HQ_PROGRESS_LEVE1: {
      LABEL: 'HQ_PROGRESS',
      VISIBLE_COUNT: 4,
      COLLECTIONS: [
        'SALES_HQ_LEV1_NAME',
        'CHANNEL_LEV1_NAME',
        'CHANNEL_LEV2_NAME',
        'CHANNEL_NL',
        'CHANNEL_LEV3_NAME',
      ]
    },
    HQ_PROGRESS_LEVE2: {
      LABEL: 'HQ_PROGRESS',
      VISIBLE_COUNT: 4,
      COLLECTIONS: [
        'SALES_HQ_LEV2_NAME',
        'CHANNEL_LEV1_NAME',
        'CHANNEL_LEV2_NAME',
        'CHANNEL_NL',
        'CHANNEL_LEV3_NAME'
      ]
    },
    HQ_PROGRESS_NATION_LOCAL: {
      LABEL: 'HQ_PROGRESS',
      VISIBLE_COUNT: 4,
      COLLECTIONS: [
        'SALES_HQ_LEV1_NAME',
        'SALES_HQ_LEV2_NAME',
        'CHANNEL_LEV1_NAME_NL',
        'CHANNEL_NL'
      ]
    },
    HQ_PROGRESS_TIMELINE: {
      LABEL: 'HQ_PROGRESS',
      VISIBLE_COUNT: 4,
      COLLECTIONS: [
        'SALES_HQ_LEV1_NAME',
        'CHANNEL_LEV1_NAME',
        'CHANNEL_LEV2_NAME',
        'CHANNEL_NL',
        'CHANNEL_LEV3_NAME',
      ]
    },
    DISTRICT_PROGRESS: {
      LABEL: 'DISTRICT_PROGRESS',
      VISIBLE_COUNT: 4,
      COLLECTIONS: [
        'SALES_HQ_LEV2_NAME',
        'CHANNEL_LEV1_NAME',
        'CHANNEL_LEV3_NAME',
        'CHANNEL_NL',
        'SALES_HQ_LEV1_NAME',
        'SALES_HQ_LEV3_NAME',
        'SALES_CENTER_NAME',
        'SALES_CONTROL_BASE_NAME',
        'SALES_CONTROL_BASE_ROUTE_CODE',
        'CHANNEL_LEV2_NAME'
      ]
    },
    DISTRICT_PROGRESS_BY_ROUTE: {
      LABEL: 'DISTRICT_PROGRESS_BY_ROUTE',
      VISIBLE_COUNT: 4,
      COLLECTIONS: [
        'SALES_HQ_LEV2_NAME',
        'SALES_HQ_LEV3_NAME',
        'CHANNEL_LEV1_NAME',
        'CHANNEL_LEV3_NAME',
        'SALES_HQ_LEV1_NAME',
        'SALES_CENTER_NAME',
        'SALES_CONTROL_BASE_NAME',
        'SALES_CONTROL_BASE_ROUTE_CODE',
        'CHANNEL_LEV2_NAME',
        'CHANNEL_NL',
      ]
    },
    PROGRESS_RANKING: {
      LABEL: 'PROGRESS_RANKING',
      VISIBLE_COUNT: 4,
      COLLECTIONS: [
        'SALES_HQ_LEV1_NAME',
        'SALES_HQ_LEV2_NAME',
        'SALES_HQ_LEV3_NAME',
        'CHANNEL_LEV1_NAME',
        'CHANNEL_LEV2_NAME',
        'CHANNEL_LEV3_NAME',
        'CHANNEL_NL'
      ]
    },
  },
  CHART_FILTER: {
    HQ1_CHANNEL_LEV: [
      {
        value : 'CHANNEL_LEV1_NAME',
        name: 'Channel Lev1 Name'
      },
      {
        value : 'CHANNEL_LEV2_NAME',
        name: 'Channel Lev2 Name'
      },
      {
        value : 'CHANNEL_LEV3_NAME',
        name: 'Channel Lev3 Name'
      },
    ],
    HQ2_CHANNEL_LEV: [
      {
        value : 'CHANNEL_LEV1_NAME',
        name: 'Channel Lev1 Name'
      },
      {
        value : 'CHANNEL_LEV2_NAME',
        name: 'Channel Lev2 Name'
      },
      {
        value : 'CHANNEL_LEV3_NAME',
        name: 'Channel Lev3 Name'
      },
    ],
    HQ_NATIONAL_CHANNEL_LEV: [
      {
        value : 'CHANNEL_LEV1_NAME',
        name: 'Channel Lev1 Name'
      },
      {
        value : 'CHANNEL_LEV2_NAME',
        name: 'Channel Lev2 Name'
      },
      {
        value : 'CHANNEL_LEV3_NAME',
        name: 'Channel Lev3 Name'
      },
    ],
    DISTRICT_PROGRESS_CHANNEL_LEV: [
      {
        value : 'CHANNEL_LEV1_NAME',
        name: 'Channel Lev1 Name'
      },
      {
        value : 'CHANNEL_LEV2_NAME',
        name: 'Channel Lev2 Name'
      },
      {
        value : 'CHANNEL_LEV3_NAME',
        name: 'Channel Lev3 Name'
      },
    ]
  }
}

export const SE_POLLING_INTERVAL = 5000;
export const SESSION_STORAGE_KEYS = {
  REDIRECT_URL: 'redirect-url'
};

export const EXCLUDE_TRANSACTION_RECORDS = ['IntageVmDashboardWeeks', 'IntageDashboardWeeks', 'IntageVmWeeks'];