import { EXCLUDE_TRANSACTION_RECORDS } from 'src/app/model/constants';

const transactionName = 'Report';
const transactionMode = 'readwrite';

export class IndexDbHelper {
    static async Set(key: string, value: any, expirationMonths: number = 2) {
        let db = window['db'] as IDBDatabase;

        if (db == null) {
            return;
        }

        let tx    = db.transaction(transactionName, transactionMode);
        let store = tx.objectStore(transactionName);
        let expirationTime = null;
        let cacheData      = null;
        const now             = new Date();

        if (expirationMonths > 0) {
            const expirationDate = new Date(now.getFullYear(), now.getMonth() + expirationMonths, now.getDate());
            expirationTime       = parseInt(expirationDate.toISOString().split('T')[0].replace(/-/g, ''));
            console.log(`Setting expiration time for key: ${key} to ${expirationDate.toISOString()}`);
        }

        if (EXCLUDE_TRANSACTION_RECORDS.includes(key)) {
          cacheData = { id: key, data: value };
        } else {
          cacheData = { id: key, data: value, expiration: expirationTime };
        }

        tx    = db.transaction(transactionName, transactionMode);
        store = tx.objectStore(transactionName);

        await store.put(cacheData);
    }

    static async Get(key: string) {
        return new Promise(function (resolve) {
            let db = window['db'] as IDBDatabase;

            if (db == null) {
                return resolve(null);
            }

            const tx      = db.transaction(transactionName, transactionMode);
            const store   = tx.objectStore(transactionName);
            const request = store.get(key);

            request.onsuccess = (event) => {
                const result = request.result;

                if (result) {
                    if (EXCLUDE_TRANSACTION_RECORDS.includes(key)) {
                      return resolve(result.data);
                    }

                    if (!result.expiration) {
                        console.log(`No expiration field found for key: ${key}. Deleting...`);
                        store.delete(key);
                        return resolve(null);
                    }

                    const expiration     = result.expiration.toString() || '';
                    const expirationDate = new Date(expiration.slice(0, 4), expiration.slice(4, 6) - 1, expiration.slice(6, 8));
                    const today          = new Date();
                    today.setHours(0, 0, 0, 0);

                    if (expirationDate < today) {
                        console.log(`Data for key: ${key} has expired. Deleting...`);
                        store.delete(key);
                        return resolve(null);
                    }

                    return resolve(result.data);
                } else {
                    return resolve(null);
                }
            };

            request.onerror = (event) => {
                console.error(`Error retrieving data for key: ${key}`, (event.target as IDBRequest).error);
                return resolve(null);
            };
        });
    }
}
